<template>
  <div>
    <v-scale-transition>
      <v-card
        class="pb-4 rounded-t-0 overflow-y-auto overflow-x-hidden"
        max-height="80vh"
      >
        <v-row justify-items-center align="center" class="mb-3">
          <v-col cols="12" class="pa-10">
            <div class="d-flex justify-center">
              <span
                class="primary--text text-h5 font-weight-bold text-center break-word text-wrap normal-line-height"
                :style="`${
                  $vuetify.breakpoint.mdAndUp ? 'max-width: 700px' : ''
                }`"
              >
                {{ $ml.get("activationSessionOneTitle") }}
              </span>
            </div>
            <v-row justify="center" class="mt-4">
              <ShiftsTableWithUsers
                fixedHeader
                showExpand
                singleExpand
                :loading="loading"
              />
            </v-row>
          </v-col>
        </v-row>

        <v-row dense align="center" :class="`ma-0 px-4`">
          <v-col
            :order="isXsScreen ? 2 : 1"
            cols="12"
            xs="12"
            sm="4"
            md="4"
            lg="7"
            :class="isXsScreen ? 'pt-2' : ''"
          >
            <v-btn
              text
              :block="isXsScreen"
              :large="isXsScreen"
              color="secondary lighten-2"
              class="mx-auto text-none text-body-2 text-md-body-1 font-weight-medium"
              @click="$emit('previous')"
            >
              {{ $ml.get("previous") }}
            </v-btn>
          </v-col>
          <v-col
            :order="isXsScreen ? 1 : 2"
            cols="12"
            xs="12"
            sm="8"
            md="8"
            lg="5"
            class=""
          >
            <v-row align="center">
              <v-col
                :order="isXsScreen ? 2 : 1"
                :class="`${isXsScreen ? '' : 'd-flex justify-end'}`"
                cols="12"
                xs="12"
                sm="4"
                md="6"
                lg="2"
              >
                <v-btn
                  x-large
                  color="accent"
                  class="text-none text-body-2 text-md-body-1 font-weight-bold"
                  outlined
                  :block="isXsScreen"
                  :large="isXsScreen"
                  @click="showRedirectDialog = true"
                >
                  {{ $ml.get("userShiftSettings") }}

                  <v-icon
                    right
                    v-text="'mdi-chevron-right'"
                    class="ml-3"
                    size="24"
                  />
                </v-btn>
              </v-col>
              <v-col
                class="d-flex justify-end"
                :order="isXsScreen ? 1 : 2"
                cols="12"
                xs="12"
                sm="8"
                md="6"
                lg="10"
              >
                <v-btn
                  x-large
                  color="accent"
                  block
                  :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-8`"
                  :loading="loadingUsers"
                  @click="handleUsersAccessSkip()"
                >
                  {{ $ml.get("okContinue") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-scale-transition>
    <NoShiftUsersDialog
      :show="showNoShiftUsersDialog"
      @continue="handleNoShiftUsersContinue"
      @fix="noShiftUsersRedirect"
    />
    <!-- REDIRECT COUNTDOWN WARNING -->
    <RedirectDialog
      :show="showRedirectDialog"
      @redirect="setRedirectRoute"
      custom-description
      max-width="650"
    >
      <v-list class="px-2 text-left py-0 my-0">
        <v-list-item v-for="({ title }, index) in redirectTips" :key="index">
          <v-list-item-action class="mr-4">
            <v-icon v-text="'mdi-check-circle'" size="24" color="success" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="text-wrap break-word normal-line-height text-body-1 mt-1"
              v-html="$ml.get(title)"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </RedirectDialog>
  </div>
</template>
<script>
import ShiftsTableWithUsers from "./ShiftsTableWithUsers.vue";
import NoShiftUsersDialog from "@/components/activation/first_step/NoShiftUsersDialog.vue";
import RedirectDialog from "@/components/base/RedirectDialog.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UsersAccessInstructionsStep",

  components: {
    NoShiftUsersDialog,
    ShiftsTableWithUsers,
    RedirectDialog,
  },

  data() {
    return {
      showNoShiftUsersDialog: false,
      showRedirectDialog: false,
      loading: false,
      routeRedirect: "/groups",
    };
  },

  computed: {
    ...mapGetters(["totalUsersWithoutGroup", "loadingUsers", "groups"]),

    isXsScreen() {
      return this.$vuetify.breakpoint.xs;
    },

    redirectTips() {
      return [
        {
          title: "dontWorryYourActvationProgressIsSaved",
        },
        {
          title: "comeBackAnytimeByClickingInControlActivation",
        },
      ];
    },
  },
  methods: {
    ...mapActions(["getGroups", "getUsers"]),
    handleUsersAccessSkip() {
      if (this.totalUsersWithoutGroup === undefined) {
        return this.getUsers().finally(() => this.handleUsersAccessSkip());
      } else if (this.totalUsersWithoutGroup === 0) {
        return this.$emit("skip");
      }
      this.showNoShiftUsersDialog = true;
    },

    handleNoShiftUsersContinue() {
      this.$emit("skip");
      this.showNoShiftUsersDialog = false;
    },
    setRedirectRoute() {
      this.showRedirectDialog = false;
      this.$router.push(this.routeRedirect);
    },
    noShiftUsersRedirect() {
      this.showNoShiftUsersDialog = false;
      this.showRedirectDialog = true;
      this.routeRedirect = "/users?action=noShiftUsers";
    },
  },
  async beforeMount() {
    if (!this.groups.length) {
      this.loading = true;
      await Promise.all([this.getGroups(), this.getUsers()]).finally(
        () => (this.loading = false)
      );
    }
  },
};
</script>
