<template>
  <v-card class="pb-6" width="100%">
    <SelectPasswordOptions
      v-if="!showDefineTemporaryPassword"
      :password-option="passwordOption"
      @password-option:update="passwordOption = $event"
    />

    <v-row v-else justify="center" align="center" class="mb-8 pt-5">
      <v-col cols="12" sm="12" md="6">
        <ChangeTemporaryPasswordIlustration />
      </v-col>

      <v-col cols="12" sm="12" md="6" class="">
        <v-card flat>
          <!-- TÍTULO -->
          <v-card-title
            class="mx-auto primary--text text-h4 font-weight-bold text-center break-word text-wrap normal-line-height"
            style="max-width: 500px"
          >
            {{ $ml.get("activationSessionTwoTitle") }}
          </v-card-title>

          <!-- DESCRIÇÃO -->
          <v-card-text
            class="break-word text-body-1 normal-line-height mb-3 mt-1"
          >
            {{ $ml.get("password_settings_subtitle") }}
          </v-card-text>

          <!-- FORMULÁRIO DE TROCA DE SENHA -->
          <DefaultPasswordForm
            get-confirmation
            :loading="loading"
            @update:password="password = $event"
            @password:valid="invalidPassword = $event"
            @confirmation-password:valid="invalidConfirmation = $event"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row dense align="center" class="px-4">
      <v-col
        :order="$vuetify.breakpoint.xs ? 2 : 1"
        cols="12"
        xs="12"
        sm="3"
        md="3"
        lg="3"
        :class="$vuetify.breakpoint.xs ? 'pt-2' : ''"
      >
        <v-btn
          text
          :large="$vuetify.breakpoint.xs"
          :block="$vuetify.breakpoint.xs"
          color="secondary lighten-2"
          class="mx-auto text-none text-body-2 text-md-body-1 font-weight-medium"
          :disabled="loading"
          @click="handlePreviousClick"
        >
          {{ $ml.get("previous") }}
        </v-btn>
      </v-col>
      <v-col
        :order="$vuetify.breakpoint.xs ? 1 : 2"
        cols="12"
        xs="12"
        sm="9"
        md="9"
        lg="9"
      >
        <v-card-actions class="pa-0 ma-0 justify-end">
          <v-btn
            v-if="showDefineTemporaryPassword"
            x-large
            color="accent"
            :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-8`"
            :disabled="invalid"
            :loading="loading"
            :block="$vuetify.breakpoint.xs"
            @click="confirmTemporaryPassword"
          >
            {{ $ml.get("confirm_password") }}
          </v-btn>
          <v-btn
            v-else
            x-large
            color="accent"
            :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-8`"
            :loading="loading"
            :block="$vuetify.breakpoint.xs"
            @click="updatePassword"
          >
            {{ $ml.get("next") }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import ChangeTemporaryPasswordIlustration from "@/components/activation/second_step/ilustrations/ChangeTemporaryPasswordIlustration";
import DefaultPasswordForm from "@/components/forms/DefaultPasswordForm.vue";
import SelectPasswordOptions from "@/components/activation/second_step/SelectPasswordOptions.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "ChangeTemporaryPasswordStep",

  components: {
    ChangeTemporaryPasswordIlustration,
    DefaultPasswordForm,
    SelectPasswordOptions,
  },
  props: {
    isConfigureTemporaryPassword: { type: Boolean, default: false },
  },

  data() {
    return {
      invalidPassword: true,

      invalidConfirmation: true,

      loading: false,

      password: "",

      passwordOption: "sendLinkToChangePasswords",

      showDefineTemporaryPassword: false,
    };
  },

  computed: {
    ...mapGetters([
      "changedActivationPassword",
      "askedGenerateRandomPassword",
      "defaultPassword",
    ]),

    invalid() {
      return this.invalidPassword || this.invalidConfirmation;
    },

    passwordChangedTips() {
      return [
        {
          title: "temporaryPasswordWarningCoWorkers",
        },
        {
          title: "temporaryPasswordFutureChanges",
        },
      ];
    },
  },

  methods: {
    ...mapActions(["editDefaultPassword"]),
    ...mapMutations([
      "setChangedPasswordState",
      "setselectedChangedPasswordMethod",
    ]),

    confirmPassword() {
      this.setChangedPasswordState(true);
      this.setselectedChangedPasswordMethod(this.passwordOption);
      this.$emit("changed");
    },

    async updatePassword() {
      if (
        ["sendLinkToChangePasswords", "createRandomPasswords"].includes(
          this.passwordOption
        )
      ) {
        this.confirmPassword();
      } else {
        this.showDefineTemporaryPassword = true;
      }
    },

    async confirmTemporaryPassword() {
      if (this.password !== this.defaultPassword) {
        this.loading = true;
        const payload = {
          password: this.password,
        };

        await this.editDefaultPassword(payload)
          .then(() => {
            this.confirmPassword();
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.confirmPassword();
      }
    },

    handlePreviousClick() {
      if (this.showDefineTemporaryPassword) {
        this.showDefineTemporaryPassword = false;
      } else {
        this.$emit("previous");
      }
    },
  },
  beforeMount() {
    if (this.isConfigureTemporaryPassword)
      this.showDefineTemporaryPassword = true;
  },
};
</script>
