<template>
  <v-data-table
    :headers="shiftTableHeaders"
    :loading="loading"
    :fixed-header="fixedHeader"
    :items="validShiftsToShow"
    :items-per-page="itemsPerPage"
    :table-height="tableHeight"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="name"
    :show-expand="showExpand"
    class="elevation-1"
    mobile-breakpoint="0"
    :height="customTableHeight"
  >
    <template v-slot:no-data> {{ $ml.get("no_data") }} </template>
    <template v-slot:[`header.name`]="{ header }">
      {{ $ml.get(header.value) }}
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <span>
        {{ item.name }}
      </span>
    </template>
    <template v-slot:[`header.users`]="{ header }">
      {{ $ml.get(header.value) }}
    </template>
    <template v-slot:[`item.users`]="{ item }">
      <MiniUsersPhoto :users="item.users" />
    </template>

    <template v-slot:expanded-item="{ item }">
      <td :colspan="rulesTableHeaders.length" class="pa-3 grey lighten-4">
        <transition name="slide-y-transition" appear>
          <v-data-table
            :headers="rulesTableHeaders"
            mobile-breakpoint="0"
            :items="item.rules"
            item-key="id"
            sort-by="day"
            :class="`${
              $vuetify.theme.dark ? 'background' : ''
            } elevation-0 overflow-y-auto`"
            :style="`${
              $vuetify.theme.dark
                ? 'border-bottom: 1px solid #424242 !important'
                : ''
            }`"
            hide-default-footer
            :items-per-page="itemsPerPage"
          >
            <template v-slot:loading>
              {{ $ml.get("loading") }}
            </template>
            <template v-slot:no-data>
              {{ $ml.get("no_data") }}
            </template>
            <template v-slot:no-results>
              {{ $ml.get("no_results") }}
            </template>

            <!-- HEADERS -->
            <template v-slot:[`header.day`]="{}">
              <span class="text-capitalize">
                {{ $ml.get("day") }}
              </span>
            </template>
            <template v-slot:[`header.start_hour`]="{ header }">
              {{ $ml.get(header.value) }}
            </template>
            <template v-slot:[`header.end_hour`]="{ header }">
              {{ $ml.get(header.value) }}
            </template>

            <!-- TBODY -->
            <template v-slot:[`item.day`]="{ item }">
              <span class="text-capitalize">
                {{ getWeeyDayNameByNumber(item.day) }}
              </span>
            </template>
            <template v-slot:[`item.updated_password_at`]="{ item }">
              {{
                item.updated_password_at
                  ? formatDateFullMonth(item.updated_password_at)
                  : "-"
              }}
            </template>
          </v-data-table>
        </transition>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import MiniUsersPhoto from "@/components/users/MiniUsersPhoto.vue";
import {
  getWeeyDayNameByNumber,
  formatDateFullMonth,
  paging,
  tableHeight,
} from "@/helpers/utils";
import { mapGetters } from "vuex";
export default {
  name: "ShiftsTableWithUsers",

  components: {
    MiniUsersPhoto,
  },

  props: {
    loading: { type: Boolean, default: false },
    fixedHeader: { type: Boolean, default: false },
    singleExpand: { type: Boolean, default: false },
    showExpand: { type: Boolean, default: false },
    customTableHeight: { type: String, default: "" },
  },

  data() {
    return {
      expanded: [],
      itemsPerPage: paging.perPage,
      itemsOptions: paging.All,
      tableHeight,
    };
  },
  computed: {
    ...mapGetters(["loadingUsers", "groups"]),

    rulesTableHeaders() {
      return [
        {
          align: "start",
          sortable: true,
          value: "day",
          width: 170,
        },
        {
          value: "start_hour",
          groupable: false,
          sortable: false,
          width: 170,
        },
        { value: "end_hour", groupable: false, sortable: false, width: 170 },
      ];
    },
    shiftTableHeaders() {
      return [
        {
          align: "start",
          sortable: true,
          value: "name",
          text: "Turnos",
        },
        {
          align: "start",
          sortable: true,
          value: "users",
          text: "Usuários",
        },
      ];
    },

    validShiftsToShow() {
      return this.groups.filter(
        (shift) => shift.users.length > 0 && shift.is_active
      );
    },
  },
  methods: {
    formatDateFullMonth,
    getWeeyDayNameByNumber,
  },
};
</script>

<style></style>
