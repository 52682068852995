<template>
  <v-dialog
    scrollable
    v-model="show"
    :width="width"
    :persistent="persistent"
    @keydown.esc="$emit('keydown.esc')"
    @click:outside="$emit('click:outside')"
  >
    <!-- rounded-xxl -->
    <v-card flat rounded="xl" class="py-8" style="overflow: hidden">
      <slot name="body">
        <v-icon v-text="`$${type}Outline`" :color="type || color" size="30" />
        <v-card-title
          v-text="title"
          class="justify-center"
          style="text-align: center; color: black"
        />
        <v-card-subtitle
          v-if="subtitle"
          v-text="subtitle"
          class="text-body-2 text-md-body-1"
          style="text-align: center"
        />
        <v-card-text
          v-text="message"
          class="pb-4 mx-2 text-body-2 text-md-body-1"
          style="text-align: center; justify-items: center; max-width: 500px"
        />
      </slot>

      <slot name="footer">
        <v-card-actions class="mx-4 justify-space-between">
          <v-btn
            v-if="showCloseBtn"
            v-text="closeText || $ml.get('close')"
            :outlined="closeStyle === 'outlined'"
            :text="closeStyle === 'text'"
            :color="color"
            class="text-none text-body-2 text-md-body-1 font-weight-bold flex-grow-1"
            large
            :disabled="loading"
            @click="$emit('close')"
          />
          <v-btn
            v-if="!readOnly"
            v-text="actionText || $ml.get('confirm')"
            :color="color"
            elevation="0"
            class="text-none text-body-2 text-md-body-1 font-weight-bold flex-grow-1"
            large
            :disabled="disabledAction || loading"
            :loading="loading"
            @click="$emit('action')"
          />
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "AlertDialog",
  props: {
    show: { type: Boolean, required: true },
    title: { type: String, required: true },
    subtitle: { type: String, default: "" },
    message: { type: String, required: true, default: "" },
    color: { type: String, default: "primary" },
    type: { type: String, default: "info" },
    width: { type: String, default: "fit-content" },
    persistent: { type: Boolean, default: true },
    actionText: { type: String, default: "" },
    disabledAction: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    closeText: { type: String, default: "" },
    closeStyle: { type: String, default: "outlined" },
    readOnly: { type: Boolean, default: false },
    showCloseBtn: { type: Boolean, default: true },
  },
};
</script>
