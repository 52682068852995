<template>
  <div class="d-flex">
    <span v-if="!users.length">No users</span>
    <div v-for="(user, index) in users" :key="index">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attr }">
          <img
            v-on="on"
            v-bind="attr"
            :src="user.photo || userPhotoDefault"
            :class="[
              'card-user-photo',
              { 'card-user-photo-offset': index > 0 },
            ]"
            v-if="index < 4"
          />
        </template>
        <span class="pa-0 ma-0">
          {{ user.name }}
        </span>
      </v-tooltip>

      <v-tooltip right v-if="index === 4">
        <template v-slot:activator="{ on, attr }">
          <div
            v-on="on"
            v-bind="attr"
            class="card-user-total-length white--text d-flex justify-center align-center font-weight-medium"
          >
            +{{ users.length - 4 }}
          </div>
        </template>
        <span v-for="(user, i) in users" :key="i"
          ><p class="pa-0 ma-0" v-if="i > 3 && i < 40">
            {{ user.name }}
          </p></span
        >
        <p v-if="users.length > 40" class="ma-0 pa-0">
          {{ `+ ${usersLeftToShow()} ${$ml.get("users")}` }}
        </p>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  name: "MiniUsersPhoto",
  props: {
    users: { type: Array, defalt: [] },
  },

  data() {
    return {
      userPhotoDefault: require("@/assets/images/user-default.png"),
    };
  },
  methods: {
    usersRangeSplited() {
      return this.users.slice(4, 40);
    },
    usersLeftToShow() {
      const usersSlicedPlusFirstFour = 44;
      return this.users.length - usersSlicedPlusFirstFour;
    },
  },
};
</script>
<style scoped>
.card-user-photo {
  width: 28px;
  height: 28px;
  border: 1.4px solid #ffffff;
  box-shadow: 0px 2.8px 2.8px 0px #00000014;
  border-radius: 50%;
}
.card-user-photo-offset {
  margin-left: -6px;
}
.card-user-total-length {
  width: 28px;
  height: 28px;
  background: #5d43a6a6 60% !important;
  border: 1.4px solid #ffffff !important;
  box-shadow: 0px 2.8px 2.8px 0px #00000014 !important;
  border-radius: 50% !important;
  font-family: Manrope !important;
  font-size: 10.5px !important;
  font-weight: 600 !important;
  line-height: 14px !important;
  text-align: left !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -6px;
  color: white;
}
</style>
