var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.shiftTableHeaders,"loading":_vm.loading,"fixed-header":_vm.fixedHeader,"items":_vm.validShiftsToShow,"items-per-page":_vm.itemsPerPage,"table-height":_vm.tableHeight,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"name","show-expand":_vm.showExpand,"mobile-breakpoint":"0","height":_vm.customTableHeight},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$ml.get("no_data"))+" ")]},proxy:true},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"header.users",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.users",fn:function(ref){
var item = ref.item;
return [_c('MiniUsersPhoto',{attrs:{"users":item.users}})]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pa-3 grey lighten-4",attrs:{"colspan":_vm.rulesTableHeaders.length}},[_c('transition',{attrs:{"name":"slide-y-transition","appear":""}},[_c('v-data-table',{class:((_vm.$vuetify.theme.dark ? 'background' : '') + " elevation-0 overflow-y-auto"),style:(("" + (_vm.$vuetify.theme.dark
              ? 'border-bottom: 1px solid #424242 !important'
              : ''))),attrs:{"headers":_vm.rulesTableHeaders,"mobile-breakpoint":"0","items":item.rules,"item-key":"id","sort-by":"day","hide-default-footer":"","items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.$ml.get("loading"))+" ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$ml.get("no_data"))+" ")]},proxy:true},{key:"no-results",fn:function(){return [_vm._v(" "+_vm._s(_vm.$ml.get("no_results"))+" ")]},proxy:true},{key:"header.day",fn:function(ref){return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$ml.get("day"))+" ")])]}},{key:"header.start_hour",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"header.end_hour",fn:function(ref){
          var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$ml.get(header.value))+" ")]}},{key:"item.day",fn:function(ref){
          var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getWeeyDayNameByNumber(item.day))+" ")])]}},{key:"item.updated_password_at",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.updated_password_at ? _vm.formatDateFullMonth(item.updated_password_at) : "-")+" ")]}}],null,true)})],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }