<template>
  <AlertDialog
    :show="show"
    color="primary"
    type="success"
    :title="$ml.get('confirm_activation_dialog_title')"
    :message="$ml.get('confirm_activation_dialog_description')"
    :action-text="$ml.get('yes_activate_control')"
    @action="$emit('yes')"
    :close-text="$ml.get('cancel')"
    close-style="text"
    @close="$emit('cancel')"
    :loading="loading"
  />
</template>

<script>
import AlertDialog from "@/components/base/AlertDialog.vue";

export default {
  name: "ConfirmActivationDialog",

  components: { AlertDialog },

  props: {
    show: { type: Boolean, required: true },
    loading: { type: Boolean, default: false },
  },
};
</script>
