<template>
  <v-card class="pb-5 mb-5 rounted-t-0" width="100%">
    <v-row justify="center" align="center" class="mb-8 pt-7">
      <v-col :class="$vuetify.breakpoint.mdAndUp ? 'px-6' : ''">
        <v-card
          flat
          class="d-flex flex-column justify-center align-center mt-8"
          style="width: 100%"
        >
          <v-card-title
            class="primary--text text-h6 text-md-h4 font-weight-bold text-center break-word text-wrap normal-line-height"
          >
            {{ $ml.get("whatDoYouWantToDoWithLoggedUsers") }}
          </v-card-title>

          <v-item-group
            v-model="selectedOptionIndex"
            mandatory
            class="mt-4"
            :disabled="loading"
            @change="$emit('select', logoutSelectedOption)"
          >
            <v-row class="pa-0 ma-0" justify="center" align="stretch">
              <v-col
                v-for="(logoutOption, index) in logoutOptions"
                :key="index"
                cols="12"
                md="4"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :class="`d-flex flex-column align-center justify-center py-4 fill-height mx-auto ${
                      active ? 'amber lighten-5' : ''
                    }`"
                    outlined
                    hover
                    height="100%"
                    :disabled="loading"
                    @click="toggle"
                  >
                    <v-icon
                      v-text="logoutOption.icon"
                      color="accent"
                      size="65"
                    />

                    <v-card-title
                      :class="`text-wrap break-word text-center text-body-1 text-md-h5 font-weight-medium ${
                        active
                          ? 'accent--text'
                          : 'secondary--text text--lighten-1'
                      }`"
                      style="max-width: 70%"
                    >
                      {{ $ml.get(logoutOption.title) }}
                    </v-card-title>

                    <v-card-text
                      class="mt-1 text-wrap break-word normal-line-height text-center text-body-2 text-md-body-1"
                    >
                      {{ $ml.get(logoutOption.subtitle) }}
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="px-4 mx-1" align="center">
      <v-col
        cols="12"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        :order="isXsScreen ? 2 : 1"
        :class="`${isXsScreen ? '' : 'd-flex justify-start'}`"
      >
        <v-btn
          text
          color="secondary lighten-2"
          :block="isXsScreen"
          :large="isXsScreen"
          class="ml-1 pa-6 text-none text-body-2 text-md-body-1 font-weight-medium"
          :disabled="loading"
          @click="$emit('previous')"
        >
          {{ $ml.get("previous") }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        :order="isXsScreen ? 1 : 2"
        :class="`${isXsScreen ? '' : 'd-flex justify-end'}`"
      >
        <v-btn
          x-large
          color="accent"
          :class="`text-none text-body-2 text-md-body-1 font-weight-bold px-11`"
          :loading="loading || loadingUsers"
          :block="isXsScreen"
          :large="isXsScreen"
          @click="$emit('logoutUsers', logoutSelectedOption)"
        >
          {{ $ml.get("confirmAndNext") }}
          <v-icon v-text="'mdi-chevron-right'" right size="24" />
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { logoutOptions } from "@/helpers/variables";
import { mapGetters } from "vuex";
export default {
  name: "SelectLogoutOptionsStep",

  props: {
    loading: { type: Boolean, default: false },
  },

  components: {},

  data() {
    return {
      selectedOptionIndex: 0,
      showScheduleActivation: false,
      logoutOptions,
    };
  },

  computed: {
    ...mapGetters(["loadingUsers"]),
    isXsScreen() {
      return this.$vuetify.breakpoint.xs;
    },

    logoutSelectedOption() {
      return this.logoutOptions[this.selectedOptionIndex].value;
    },
  },
};
</script>
