<template>
  <AlertDialog
    :show="showDialog"
    color="primary"
    type="warning"
    :title="$ml.get('no_shift_users_dialog_title')"
    :message="$ml.get('no_shift_users_dialog_message')"
    :loading="loading"
    :action-text="$ml.get('fix')"
    @action="$emit('fix')"
    :close-text="$ml.get('continue')"
    @close="showDialog = false"
  />
</template>

<script>
import AlertDialog from "@/components/base/AlertDialog.vue";

export default {
  name: "NoShiftUsersDialog",

  components: { AlertDialog },

  props: {
    show: { type: Boolean, required: true },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("continue");
      },
    },
  },
};
</script>
