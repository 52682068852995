<template>
  <HeaderDialog
    :show="showDialog"
    color="primary"
    :title="$ml.get('activation_date_title')"
    :loading="loading"
    @close="showDialog = false"
    @action="scheduleActivation"
    width="700"
    close-text="cancel"
  >
    <template v-slot:body>
      <v-row>
        <v-col>
          <p>
            {{ $ml.get("schedule_activation_description") }}
          </p>
          <Alert small dense :showAction="false">{{
            $ml.get("schedule_activation_logout_alert")
          }}</Alert>
        </v-col>
      </v-row>
      <v-row class="ml-0 mr-0">
        <v-col
          :class="`${$vuetify.breakpoint.xs ? 'px-0' : 'pl-0'}`"
          cols="12"
          lg="6"
          md="6"
          sm="6"
          xs="6"
        >
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormatted"
                label="Data"
                :hint="$ml.get('activation_date_hint')"
                persistent-hint
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :min="today"
              :max="addDays(14)"
              no-title
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          :class="`${$vuetify.breakpoint.xs ? 'px-0' : 'pr-0'}`"
          lg="6"
          md="6"
          sm="6"
          xs="6"
          cols="12"
        >
          <v-text-field
            v-model="hour"
            :label="$ml.get('hour')"
            type="time"
            hide-details
            append-icon="mdi-clock"
            outlined
          />
        </v-col>
      </v-row>
    </template>
  </HeaderDialog>
</template>
<script>
import {
  today,
  addDays,
  formatDate,
  createDatetimeWithDateAndHour,
} from "@/helpers/utils";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ScheduleActivationDialog",
  props: {
    show: { type: Boolean, required: true },
  },

  data() {
    return {
      date: today,
      menu: false,
      loading: false,
      hour: "23:59",
      today,
    };
  },

  computed: {
    ...mapGetters(["token", "domainsToActivate"]),

    showDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  methods: {
    ...mapActions(["setSnackbarDefault"]),
    ...mapMutations(["setScheduleActivationInfo"]),

    addDays,

    formatDate,

    scheduleActivation() {
      this.loading = true;
      this.$emit("updateLoading", true);

      const url = `${process.env.VUE_APP_API_BASE_URL}/sso/activate:async`;

      const payload = {
        activation_date: createDatetimeWithDateAndHour(this.date, this.hour),
        domains: this.domainsToActivate,
      };

      this.$axios
        .post(url, payload, {
          headers: { Authorization: "Bearer " + this.token },
        })
        .then(({ data }) => {
          this.setSnackbarDefault({
            message: "success_schedule_activation",
            color: "success",
            show: true,
          });

          this.setScheduleActivationInfo(data);

          this.$router.push("/activation-completed");
        })
        .catch(() => {
          this.setSnackbarDefault({
            message: "error_activation_async",
            color: "error",
            show: true,
          });
        })
        .finally(() => {
          this.loading = false;
          this.$emit("updateLoading", false);
        });
    },
  },
};
</script>
<style>
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.v-text-field__details {
  padding: 0 !important;
}
</style>
