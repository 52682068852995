<template>
  <div>
    <v-row justify="center" align="center" class="mb-8 pt-7">
      <v-col :class="$vuetify.breakpoint.mdAndUp ? 'px-6' : ''">
        <play-video-button
          openInNewTab
          :idYoutubeVideo="videoId"
          btnLabel="watch_explanation"
          justifyBtn="end"
          class="mr-2"
        />
        <v-card
          flat
          class="d-flex flex-column justify-center align-center mt-2"
          style="width: 100%"
        >
          <v-card-title
            class="primary--text text-h5 font-weight-bold text-center break-word text-wrap normal-line-height mb-3"
          >
            {{ $ml.get("selectChangePasswordStepTitle") }}
          </v-card-title>
          <v-card-subtitle
            class="secondary--text text-body-2 text-center break-word text-wrap normal-line-height"
          >
            {{ $ml.get("selectChangePasswordStepSubtitle") }}
            <p>
              {{ $ml.get("selectChangePasswordStepSubtitle2") }}
            </p>
          </v-card-subtitle>

          <v-item-group
            v-model="selectedPasswordOption"
            mandatory
            :disabled="loading"
          >
            <v-row class="pa-0 ma-0" justify="center" align="stretch">
              <v-col
                v-for="(passwordOption, index) in passwordOptions"
                :key="index"
                cols="12"
                md="4"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    :class="`d-flex flex-column align-center justify-center py-4 fill-height mx-auto ${
                      active ? 'amber lighten-5' : ''
                    }`"
                    outlined
                    hover
                    height="100%"
                    :disabled="loading"
                    @click="toggle"
                  >
                    <v-icon
                      v-text="passwordOption.icon"
                      color="accent"
                      size="45"
                    />

                    <v-card-title
                      :class="`text-wrap break-word text-center text-h6 font-weight-medium ${
                        active
                          ? 'accent--text'
                          : 'secondary--text text--lighten-1'
                      }`"
                    >
                      {{ $ml.get(passwordOption.title) }}
                    </v-card-title>

                    <v-card-text
                      class="mt-1 text-wrap break-word normal-line-height text-center text-body-2"
                    >
                      {{ $ml.get(passwordOption.subtitle) }}
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import PlayVideoButton from "@/components/base/PlayVideoButton.vue";
import { passwordOptions, youtube_videos_ids } from "@/helpers/variables";
import { mapGetters } from "vuex";

export default {
  components: { PlayVideoButton },
  name: "SelectPasswordOptions",

  props: {
    loading: { type: Boolean, default: false },
    passwordOption: {
      type: String,
      default: () => "sendLinkToChangePasswords",
    },
  },

  data() {
    return {
      passwordOptions,
    };
  },

  computed: {
    ...mapGetters(["isMicrosoft"]),

    selectedPasswordOption: {
      get() {
        return this.passwordOptions.findIndex(
          (option) => option.value === this.passwordOption
        );
      },
      set(value) {
        this.$emit(
          "password-option:update",
          this.passwordOptions[value || 0].value
        );
      },
    },

    videoId() {
      return youtube_videos_ids.activation_change_password;
    },
  },
};
</script>
