<template>
  <div>
    <v-scale-transition>
      <v-card class="pb-4 rounded-t-0" color="white">
        <v-row justify-items-center align="center" class="mb-3">
          <v-col cols="12" class="px-10 pt-10">
            <div class="d-flex justify-center mt-5">
              <span
                class="primary--text text-h5 font-weight-bold text-center break-word text-wrap normal-line-height"
                :style="`${
                  $vuetify.breakpoint.mdAndUp ? 'max-width: 650px' : ''
                }`"
              >
                {{ $ml.get("activationStepFourTitle") }}
              </span>
            </div>
            <v-row justify="center" class="mt-4">
              <v-col cols="12" sm="12" xs="12" md="6" lg="6">
                <v-col cols="12" v-if="isMicrosoft" class="text-center">
                  <h4
                    class="mb-2 text-wrap break-word text-center text-body-1 text-h5 font-weight-medium secondary--text"
                  >
                    {{ $ml.get("activation_domain") }}
                  </h4>
                  <v-autocomplete
                    v-model="domainsToActivate"
                    :items="domainsToActivate"
                    multiple
                    filled
                    hide-no-data
                    class="ma-0"
                    style="width: 100%"
                    disabled
                    hide-details
                  >
                    <template v-slot:item="{ item }">
                      {{ item }}
                    </template>
                    <template v-slot:selection="{ item }">
                      <v-chip small>
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <h4
                    class="mb-2 text-wrap break-word text-center text-body-1 text-h5 font-weight-medium secondary--text"
                  >
                    {{ $ml.get("activationStepOneTitle") }}
                  </h4>
                  <ShiftsTableWithUsers
                    :customTableHeight="customTableHeight"
                  />
                </v-col>
              </v-col>
              <v-col cols="12" sm="12" xs="12" md="6" lg="6">
                <v-col
                  v-for="(item, i) in selectedLogoutAndPasswordMethods"
                  :key="i"
                  cols="12"
                >
                  <h4
                    class="mb-2 text-wrap break-word text-center text-body-1 text-h5 font-weight-medium secondary--text"
                  >
                    {{ $ml.get(item.type_key) }}
                  </h4>
                  <v-card
                    class="d-flex flex-column align-center justify-center py-1 fill-height mx-auto amber lighten-5"
                    outlined
                    hover
                    height="100%"
                    width="100vw"
                    :disabled="loading"
                  >
                    <v-icon v-text="item.icon" color="accent" size="45" />

                    <v-card-title
                      class="text-wrap break-word text-center text-body-1 text-h5 font-weight-medium accent--text"
                      style="max-width: 70%"
                    >
                      {{ $ml.get(item.title) }}
                    </v-card-title>

                    <v-card-text
                      class="mt-1 text-wrap break-word normal-line-height text-center text-body-2"
                    >
                      {{ $ml.get(item.subtitle) }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row dense align="center" class="px-4">
          <v-col
            :order="isXsScreen ? 3 : 1"
            cols="12"
            xs="12"
            sm="3"
            md="3"
            lg="6"
          >
            <v-btn
              text
              color="secondary lighten-2"
              :block="isXsScreen"
              :large="isXsScreen"
              class="mx-auto text-none text-body-2 text-md-body-1 font-weight-medium"
              :disabled="loading || activationLoading || loadingUsers"
              @click="$emit('previous')"
            >
              {{ $ml.get("previous") }}
            </v-btn>
          </v-col>
          <v-col
            :order="isXsScreen ? 1 : 2"
            cols="12"
            xs="12"
            sm="9"
            md="9"
            lg="6"
            class=""
          >
            <v-row align="center">
              <v-col
                order="2"
                cols="12"
                xs="12"
                sm="4"
                md="6"
                lg="3"
                :class="`${isXsScreen ? '' : 'd-flex justify-end'}`"
              >
                <v-btn
                  outlined
                  x-large
                  color="accent"
                  class="text-none text-body-2 text-md-body-1 font-weight-bold"
                  :loading="loading || activationLoading || loadingUsers"
                  :block="isXsScreen"
                  @click="showScheduleActivation = true"
                >
                  {{ $ml.get("schedule_activation") }}
                  <v-icon
                    v-if="!isXsScreen"
                    v-text="'mdi-chevron-right'"
                    right
                  />
                </v-btn>
              </v-col>
              <v-col
                :order="isXsScreen ? 1 : 2"
                cols="12"
                xs="12"
                sm="8"
                md="6"
                lg="9"
                :class="`d-flex ${
                  isXsScreen ? ' justify-start' : 'justify-end'
                }`"
              >
                <v-btn
                  x-large
                  color="accent"
                  class="text-none text-body-2 text-md-body-1 font-weight-bold"
                  :loading="loading || activationLoading || loadingUsers"
                  :block="isXsScreen"
                  @click="$emit('next')"
                >
                  {{ $ml.get("confirm_and_active_control") }}
                  <v-icon
                    v-if="!isXsScreen"
                    v-text="'mdi-chevron-right'"
                    right
                  />
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-scale-transition>
    <ScheduleActivationDialog
      :show="showScheduleActivation"
      @close="showScheduleActivation = false"
      @updateLoading="updateLoading"
    />
  </div>
</template>
<script>
import ScheduleActivationDialog from "@/components/activation/fourth_step/ScheduleActivationDialog.vue";
import ShiftsTableWithUsers from "../first_step/ShiftsTableWithUsers.vue";
import { passwordOptions, logoutOptions } from "@/helpers/variables";
import { mapGetters } from "vuex";

export default {
  name: "ConfirmAndActivate",

  components: {
    ScheduleActivationDialog,
    ShiftsTableWithUsers,
  },

  props: {
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      activationLoading: false,
      showScheduleActivation: false,
    };
  },

  computed: {
    ...mapGetters([
      "domainsToActivate",
      "isMicrosoft",
      "selectedChangedPasswordMethod",
      "selectedLogoutOption",
      "loadingUsers",
    ]),

    isXsScreen() {
      return this.$vuetify.breakpoint.xs;
    },

    selectedLogoutAndPasswordMethods() {
      const selectedPasswordMethod = passwordOptions.find(
        (pwdOption) => pwdOption.value === this.selectedChangedPasswordMethod
      );
      const selectedLogoutMethod = logoutOptions.find(
        (logoutOption) => logoutOption.value === this.selectedLogoutOption
      );
      return [
        { type_key: "activationStepTwoTitle", ...selectedPasswordMethod },
        { type_key: "activationStepThreeTitle", ...selectedLogoutMethod },
      ];
    },

    customTableHeight() {
      return this.isMicrosoft || this.$vuetify.breakpoint.smAndDown
        ? ""
        : "32vh";
    },
  },
  methods: {
    updateLoading() {
      this.activationLoading = !this.activationLoading;
    },
  },
};
</script>
