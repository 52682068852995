var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[(!_vm.users.length)?_c('span',[_vm._v("No users")]):_vm._e(),_vm._l((_vm.users),function(user,index){return _c('div',{key:index},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [(index < 4)?_c('img',_vm._g(_vm._b({class:[
            'card-user-photo',
            { 'card-user-photo-offset': index > 0 } ],attrs:{"src":user.photo || _vm.userPhotoDefault}},'img',attr,false),on)):_vm._e()]}}],null,true)},[_c('span',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(user.name)+" ")])]),(index === 4)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attr = ref.attr;
return [_c('div',_vm._g(_vm._b({staticClass:"card-user-total-length white--text d-flex justify-center align-center font-weight-medium"},'div',attr,false),on),[_vm._v(" +"+_vm._s(_vm.users.length - 4)+" ")])]}}],null,true)},[_vm._l((_vm.users),function(user,i){return _c('span',{key:i},[(i > 3 && i < 40)?_c('p',{staticClass:"pa-0 ma-0"},[_vm._v(" "+_vm._s(user.name)+" ")]):_vm._e()])}),(_vm.users.length > 40)?_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(("+ " + (_vm.usersLeftToShow()) + " " + (_vm.$ml.get("users"))))+" ")]):_vm._e()],2):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }